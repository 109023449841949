<template>
    <BT-Blade-Items
        v-if="locationSelected"
        addBladeName="customer-consignment"
        bladeName="customer-consignments"
        :bladesData="bladesData"
        canAdd
        :dependantBlades="[{ bladeName: 'customer-consignment' }]"
        :getParams="getParams"
        :headers="[
            { text: 'CO#', value: 'consignmentNumber' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' },
            { text: 'Depart From', value: 'departureLocation.locationName' },
            { text: 'Deliver To', value: 'destinationLocation', textFilter: 'toCompanyNameAndLocationLine' }]"
        :loading="isLoading"
        minimizeOnSelect
        navigation="customer-consignments"
        :refreshToggle="refreshToggle"
        title="Customer Consignments"
        useServerPagination
        :defaultBladeWidth="900">
        <template v-slot:settings>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Actions</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Readying Consignments</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.consignmentDispatchGuide)"
                                    v-model="item.consignmentDispatchTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.consignmentDispatchGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Who Usually Organizes Couriering</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    v-model="item.courierSelectorTrigger"
                                    defaultValue="Any"
                                    :toggleOptions="[
                                        { text: 'Anyone', value: 'Any', icon: 'mdi-earth' },
                                        { text: 'Customer', value: 'Customer', icon: 'mdi-account' }, 
                                        { text: 'Supplier', value: 'Supplier', icon: 'mdi-factory' }]"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Courier Selecting</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'courier-pointers' }" title="Manage Automatic Courier Selecting">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.courierSelectionGuideAsSupplier)"
                                    v-model="item.courierSelectionTriggerAsSupplier"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon 
                                    v-model="item.courierSelectionGuideAsSupplier"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Consignment Path Planning</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'template-optimization-hub' }" title="Manage Automatic Consignment Path Planning">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.routePlanningGuide)"
                                    v-model="item.routePlanningTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.routePlanningGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Consignment Path Moving</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.routeMovementGuide)"
                                    v-model="item.routeMovementTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.routeMovementGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
        <template v-slot:actionsRight>
            <BT-Menu-Select
                
                icon="mdi-map-marker-radius"
                item-text="locationName"
                item-value="id"
                navigation="locations"
                v-model="locationID" />
        </template>
        <template v-slot:itemActions="{ item }">
            <v-icon
                v-if="item.isSourceChanged"
                class="mx-1"
                color="warning"
                small
                title="Locations or Due Date have changed">mdi-alert-circle-outline</v-icon>

            <v-icon
                :class="item.readyOn != null ? 'success--text mx-1' : 'mx-1'"
                small
                :title="item.readyOn != null ? 'Ready' : 'Not Ready To Courier'">mdi-check-underline</v-icon>
            
            <BT-Single-Action
                v-if="!item.isOrdered && !item.isBuyerCourier"
                icon="mdi-pickaxe"
                title="Customer Will Pick Up"
                @click="autoOrder(item, true)" />

            <BT-Single-Action
                v-if="!item.isOrdered && !item.isBuyerCourier"
                icon="mdi-account-multiple"
                title="Request Courier"
                @click="autoOrder(item)" />
            <v-icon 
                v-else-if="!item.isOrdered"
                class="warning--text mx-1"
                small
                title="Customer organizing courier. Not organized yet.">mdi-account-multiple</v-icon>
            <v-icon
                v-else
                class="success--text mx-1"
                small
                :title="item.isBuyerCourier ? 'Customer has organized courier' : 'Supplier has organized courier'">mdi-account-multiple</v-icon>

            <v-icon 
                :class="item.packedOn != null ? 'success--text mx-1' : 'mx-1'"
                small
                :title="item.packedOn != null ? 'Packed' : 'Not Packed'">mdi-clipboard-outline</v-icon>

            <BT-Snack v-model="msg" />
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Customer-Consignments-Blade',
    components: {
        BTMenuSelect: () => import('~components/BT-Menu-Select.vue'),
        BTSingleAction: () => import('~components/BT-Single-Action.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    data: function() {
        return {
            isLoading: false,
            locationID: null,
            locationSelected: false,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: null
    },
    watch: {
        locationID() {
            this.refreshToggle = !this.refreshToggle;
        }
    },
    mounted() {
        if (this.locationID == null || this.locationSelected == false) {
            try {
                this.locationID = this.selectedLocationID();
                // if (this.locationID == null) {
                //     this.locationID = await this.$selectItem({
                //         navigation: 'locations',
                //         itemText: 'locationName',
                //         itemValue: 'id'
                //     });
                // }
            }
            finally {
                this.locationSelected = true;
            }
        }
    },
    methods: {
        async autoOrder(item, isPickup = null) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var data = {
                    rowVersion: item.rowVersion,
                    id: item.id,
                    isReadyToOrderCourier: true,
                    isOrderCourierRequest: isPickup === true ? false : true,
                    isPickupRequest: isPickup
                }

                var res = await this.$BlitzIt.store.patch('customer-consignments', data);
                
                if (res.isOrdered) {
                    item.isOrdered = true;
                }
                else {
                    this.msg = 'Refresh List To See Results';
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        getParams(item) {
            return {
                includeDetails: false,
                customerIDs: item.data?.customerID,
                departureLocationID: this.locationID
            }
        }
    }
}
</script>